/**
 * Interface for Newsletter
 */

//** List of current types of newsletters */
export enum NEWSLETTER_TYPE {
    NEWSLETTER_PRO = "826ced5acd",
    NEWSLETTER_NHI = "bfe3cab5f1",
    NEWSLETTER_DIABETES = "dc3c68d4ae",
    NEWSLETTER_TRAINING = "fa35279596",
    NEWSLETTER_PREGNANCY = "ee2263beea",
    NEWSLETTER_TODDLER = "e940ce79bd",
    NEWSLETTER_MENOPAUS = "8357928190",
    NEWSLETTER_HEADACHE = "6ff3ebd651",
    NEWSLETTER_BREASTCANCER = "2e0d23a979"
}

//** Interface for updating the mailchimp member list */
export interface INewsletter {
    /** Email adress provided by the user */
    email: string
    /** Id of newsletter*/
    newsletterId: NEWSLETTER_TYPE
    /** Interests for spesific newsletter */
    interests?: {
        [key: string]: boolean
    }
    /** Merge-fields for spesific newsletter*/
    merge_fields?: {
        [key: string]: string
    }
}
