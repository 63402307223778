import { FormEvent, useEffect, useRef } from "react"
import { t } from "translations"

import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

export default function NewsletterTextFieldWithButton({
    fullWidth,
    palette,
    email,
    handleSetEmail,
    isSending,
    onInvalid,
    emailError
}: {
    fullWidth: boolean
    palette: object
    email: string
    handleSetEmail: (email: string) => void
    isSending: boolean
    onInvalid: (e: FormEvent<HTMLDivElement>) => void
    emailError: boolean
}) {
    const errorRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (emailError)
            errorRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
    }, [emailError])

    return (
        <>
            <StyledTextField
                ref={errorRef}
                palette={palette}
                error={emailError}
                required
                fullWidth
                size="small"
                value={email}
                type="email"
                onInvalid={e => onInvalid(e)}
                onChange={e => handleSetEmail(e.target.value)}
                placeholder={t.newsletter.enterEmail}
                inputProps={{
                    type: "email"
                }}
                InputProps={{
                    endAdornment: (
                        <StyledTextfieldButton
                            palette={palette}
                            fullwidth={fullWidth === true ? "true" : "false"}
                            color="primary"
                            variant="contained"
                            type="submit">
                            {isSending ? (
                                <CircularProgress
                                    size={30}
                                    sx={{ color: "white" }}
                                />
                            ) : (
                                `${t.newsletter.subscribeButton}`
                            )}
                        </StyledTextfieldButton>
                    )
                }}
            />
            {emailError && (
                <StyledErrorText
                    variant="subtitle2"
                    color="error">
                    {t.newsletter.invalidEmail}
                </StyledErrorText>
            )}

            {!fullWidth && (
                <StyledSmallScreenButton
                    palette={palette}
                    size="large"
                    variant="contained"
                    type="submit">
                    {isSending ? (
                        <CircularProgress
                            size={30}
                            sx={{ color: "white" }}
                        />
                    ) : (
                        `${t.newsletter.subscribeButton}`
                    )}
                </StyledSmallScreenButton>
            )}
        </>
    )
}

const StyledTextField = styled(TextField)<{ palette: any }>(
    ({ theme, palette }) => ({
        backgroundColor: theme.palette.common.white,
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.dark,
            borderRadius: 0
        },

        [theme.breakpoints.up("md")]: {
            "& .MuiInputBase-root  ": {
                paddingRight: theme.spacing(0),
                "& .MuiOutlinedInput-input": {
                    padding: "0 14px",
                    height: "42px"
                }
            }
        }
    })
)

const StyledTextfieldButton = styled(Button)<{
    palette: any
    fullwidth: string
}>(({ palette, fullwidth, theme }) => ({
    display: "none",
    [theme.breakpoints.up("md")]: {
        backgroundColor: `${palette.dark}`,
        color: palette.contrastText,
        display: fullwidth === "true" ? "block" : "none",
        width: "210px",
        height: "42px",
        borderRadius: 0,
        "&:hover": {
            backgroundColor: `${palette.light}`
        },
        "&:focus": {
            outlineOffset: "-2px"
        }
    }
}))

const StyledSmallScreenButton = styled(Button)<{
    palette: any
}>(({ palette }) => ({
    marginRight: "auto",
    marginLeft: 0,
    display: "block",
    backgroundColor: `${palette.dark}`,
    color: palette.contrastText,
    width: "150px",
    height: "42px",
    borderRadius: 0,
    "&:hover": {
        backgroundColor: `${palette.light}`,
        color: palette.contrastText
    },
    "&:focus": {
        outlineOffset: "-2px"
    }
}))

const StyledErrorText = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 1),
    marginTop: theme.spacing(-2.5)
}))
