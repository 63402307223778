import { IAddOrUpdateMemberResponse } from "@nhi/newsletter/mailchimp"
import { post } from "@nhi/utils"

import { INewsletter } from "../../mailchimp/interfaces/INewsletter"

/**
 * Subscribes the user to spesific newsletter
 * @param email User email address
 * @param newsletterId Newsletter ID
 * @param interests List of interests chosen by the user
 * @param merge_fields List of merge fields chosen by the user
 * @returns
 */

export async function setNewsletterSubscription({
    email,
    newsletterId,
    interests,
    merge_fields
}: INewsletter): Promise<IAddOrUpdateMemberResponse> {
    const body = JSON.stringify({
        email: email,
        newsletterId: newsletterId,
        interests: interests,
        mergeFields: merge_fields
    })

    const { data } = await post<IAddOrUpdateMemberResponse>(
        "/api/newsletter",
        body
    )

    return data as IAddOrUpdateMemberResponse
}
