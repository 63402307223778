import { useEffect, useRef, useState } from "react"
import { t } from "translations"

import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, {
    SelectProps as MuiSelectProps,
    SelectChangeEvent
} from "@mui/material/Select"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

export interface INewsletterSelect {
    type: SelectTypes
    options: SelectOption[] | string[]
    handleChange: (event: SelectChangeEvent<any>) => void
    name: string
    label: string
    selectedValue: string | undefined
    SelectProps?: MuiSelectProps
    required: boolean
    palette: object
}
export interface SelectOption {
    id: string
    name: string
}

type SelectTypes = "mergefields" | "interests"

export default function NewsletterSelect({
    type,
    label,
    options,
    selectedValue,
    name,
    handleChange,
    required,
    palette
}: INewsletterSelect) {
    const [error, setError] = useState(false)

    useEffect(() => {
        if (selectedValue !== undefined && selectedValue !== "") setError(false)
    }, [selectedValue])

    const errorRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (error)
            errorRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
    }, [error])

    return (
        <FormControl ref={errorRef}>
            <StyledInputLabel size="small">{label}</StyledInputLabel>

            <StyledSelect
                notched={false}
                palette={palette}
                key={type}
                name={name}
                fullWidth
                onInvalid={e => {
                    e.preventDefault()
                    setError(true)
                }}
                error={error}
                required={required}
                label={label}
                labelId={label}
                id={label}
                value={selectedValue}
                onChange={handleChange}>
                <MenuItem
                    disabled
                    divider>
                    {label}
                </MenuItem>
                {options.map((option: any) => (
                    <MenuItem
                        key={type === "interests" ? option.name : option}
                        value={type === "interests" ? option.id : option}>
                        {type === "interests" ? option.name : option}
                    </MenuItem>
                ))}
            </StyledSelect>
            {error && (
                <StyledErrorText
                    variant="subtitle2"
                    color="error">
                    {t.newsletter.requiredField}
                </StyledErrorText>
            )}
        </FormControl>
    )
}

const StyledSelect = styled(Select)<{ palette: any }>(({ theme, palette }) => ({
    backgroundColor: theme.palette.common.white,
    paddingRight: theme.spacing(1),
    height: "42px",
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: palette.dark,
        borderRadius: 0
    }
}))

const StyledInputLabel = styled(InputLabel)({
    "&[data-shrink='true']": {
        display: "none"
    }
})

const StyledErrorText = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 1),
    marginBottom: theme.spacing(-1)
}))
