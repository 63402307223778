import { NEWSLETTER_TYPE } from "./interfaces/INewsletter"
import { INewsletterList } from "./interfaces/INewsletterList"

export function getNewslettterType(newsletterType: string) {
    switch (newsletterType) {
        case "newsletter_pro":
            return NEWSLETTER_TYPE.NEWSLETTER_PRO
        case "newsletter_nhi":
            return NEWSLETTER_TYPE.NEWSLETTER_NHI
        case "newsletter_diabetes":
            return NEWSLETTER_TYPE.NEWSLETTER_DIABETES
        case "newsletter_training":
            return NEWSLETTER_TYPE.NEWSLETTER_TRAINING
        case "newsletter_pregnancy":
            return NEWSLETTER_TYPE.NEWSLETTER_PREGNANCY
        case "newsletter_toddler":
            return NEWSLETTER_TYPE.NEWSLETTER_TODDLER
        case "newsletter_menopaus":
            return NEWSLETTER_TYPE.NEWSLETTER_MENOPAUS
        case "newsletter_headache":
            return NEWSLETTER_TYPE.NEWSLETTER_HEADACHE
        case "newsletter_breastcancer":
            return NEWSLETTER_TYPE.NEWSLETTER_BREASTCANCER
        default:
            return null
    }
}

export function getNewsletterData(
    listId: NEWSLETTER_TYPE,
    newsletterList: INewsletterList[]
) {
    if (!newsletterList) return undefined
    const list = newsletterList.find(item => item.id === listId)
    return list
}
